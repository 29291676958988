const load  = (component) => {
    return () => import(`../views/${component}.vue`)
}


export default [
    {
        path: '/pack/abonnement',
        name: 'pack-abonnements',
        component: load('Pack/Abonnement')
    },
    {
        path: '/pack/abonnement/:uid',
        name: 'pack-abonnment-detail',
        component:  load('Pack/Detail')

    }

]