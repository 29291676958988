import { saveToken, getToken, destroyToken } from './localstorage'
import { ID_TOKEN_KEY } from '../constants/app'

export function sigup (value) {
  saveToken(ID_TOKEN_KEY, value)
}

export function login () {
  return getToken(ID_TOKEN_KEY)
}

export function signOut () {
  destroyToken(ID_TOKEN_KEY)
}

export function isLogin () {
  return !!getToken(ID_TOKEN_KEY)
}

export function loadComponent(component){
  return () => import(`../views/${component}.vue`)
}

// export const saveFirebaseToken = (token) => {
//   saveToken(ID_FIREBASE_TOKEN_KEY, token)
// }

// export const saveTypeUser = (type) => {
//   saveToken(TYPE_USER, type)
// }

// export const isAdmin = () => {
//   let typeUser = getToken(TYPE_USER)
//   if (!typeUser) { return false }
//   return ADMIN_USERS.includes(typeUser)
// }
