import { onError } from "apollo-link-error";
import moment from 'moment'
import { getToken } from "./localstorage";
import { TOKEN_EXPIRE } from "../constants/app";
import {signOut} from "../plugins/authService"
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log(`Error encountred`)  
  if (graphQLErrors) {
      graphQLErrors.forEach(({message, location, path}) =>{
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
        )
      })
      graphQLErrors.map(({ message }) => {
        if (`${message}` === 'Unauthenticated.') {
            console.log(message)
            const oldHeader = operation.getContext().headers
            console.log(oldHeader)

            // 
            return forward(operation);
        }
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // Check if token expire
      if(parseInt(getToken(TOKEN_EXPIRE)) < moment().format('X')){
        // logout user
        signOut()
        location.reload()
      }
    }
  })

  export default errorLink