import jQuery from 'jquery'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apolloProvider from './plugins/apollo'
import './filters'
import './plugins/graph'
import './registerServiceWorker'
// import './plugins/print'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

window.jQuery = jQuery
const $ = jQuery

require('./assets/css/app.scss')
require('./assets/js')
$.fn.select2.defaults.set('theme', 'bootstrap')
new Vue({
  render: h => h(App),
  router,
  store,
  apolloProvider,
  components: { App }
}).$mount('#app')
