const state = {
   
    modeVies: [],
    sections: [],
    questions: [],
    responses: [],
    anamneses: [],
    indexQuestionnaire: 0,
    reviewExamen: [],
    examenCliniques: [],
    nbPossibleResponse: 1,
    typeAnalyses: [],
    categorieAnalyses: [],
    typeRecherches: [],
    examens: [],
    analyses: [],
    resultatAnalyses: [],
    analyseFiles: [],
    typeMaladies: [],
    maladies: [],
    medicaments: [],
    familleMedicaments: [],
    typeParasites: [],
    antiParasitaires: [],
    vaccins: [],
    vaccinations: [],
    traitements: [],
    indexMedicaments: 0,
    traitementMedocs: [],
    typePronostics:  [],
    pronostics: [],
    motifConsultations: [],
    typeChirurgies: [],
    chirurgies: [],
    ficheAnalyses: [],
    typeFicheAnalyses: [],
    ficheSections: [],
    ficheParametres: [],
    ficheReferences: [],
    toilettageActivities: [],
    toilettages: []
}

const getters = {
    
    modeVies: state => state.modeVies,
    sections: state => state.sections,
    questions: state => state.questions,
    responses: state => state.responses,
    anamneses: state => state.anamneses,
    indexQuestionnaire: state => state.indexQuestionnaire,
    reviewExamen: state => state.reviewExamen,
    examenCliniques: state => state.examenCliniques,
    nbPossibleResponse: state => state.nbPossibleResponse,
    typeAnalyses: state => state.typeAnalyses,
    categorieAnalyses: state => state.categorieAnalyses,
    typeRecherches: state => state.typeRecherches,
    examens: state => state.examens,
    analyses: state => state.analyses,
    resultatAnalyses: state => state.resultatAnalyses,
    analyseFiles: state => state.analyseFiles,
    typeMaladies: state => state.typeMaladies,
    maladies: state => state.maladies,
    medicaments: state => state.medicaments,
    familleMedicaments: state => state.familleMedicaments,
    typeParasites: state => state.typeParasites,
    antiParasitaires: state => state.antiParasitaires,
    vaccins: state => state.vaccins,
    vaccinations: state => state.vaccinations,
    traitements: state => state.traitements,
    indexMedicaments: state => state.indexMedicaments,
    traitementMedocs: state => state.traitementMedocs,
    typePronostics: state  => state.typePronostics,
    pronostics: state => state.pronostics,
    motifConsultations: state => state.motifConsultations,
    typeChirurgies: state => state.typeChirurgies,
    chirurgies: state => state.chirurgies,
    ficheAnalyses: state => state.ficheAnalyses,
    typeFicheAnalyses: state => state.typeFicheAnalyses,
    ficheSections: state => state.ficheSections,
    ficheParametres: state => state.ficheParametres,
    ficheReferences: state => state.ficheReferences,
    toilettageActivities: state => state.toilettageActivities,
    toilettages: state => state.toilettages
}

const actions = {

}

const mutations = {
    /** Mode de vie  */
    SET_MODE_VIES: (state, list) => { state.modeVies =  list},
    ADD_MODE_VIE: (state, mode) => { state.modeVies.push(mode)},
    UPDATE_MODE_VIE: (state, mode) => { state.modeVies= state.modeVies.filter(item => item.uid !== mode.uid); state.modeVies.push(mode) },
    DELETE_MODE_VIE: (state, mode) =>{ state.modeVies= state.modeVies.filter(item => item.uid !== mode.uid)},

    /** Sections */
    SET_SECTIONS: (state, list) => { state.sections =  list},
    ADD_SECTION: (state, section) => { state.sections.push(section)},
    UPDATE_SECTION: (state, section) => { state.sections = state.sections.filter(item => item.uid !== section.uid); state.sections.push(section) },
    DELETE_SECTION:(state, section) => { state.sections = state.sections.filter(item => item.uid !== section.uid)},

    /** Questionnaires */
    SET_QUESTIONS: (state, list) => { state.questions =  list },
    ADD_QUESTION: (state, question) => { state.questions.push(question) },
    UPDATE_QUESTION: (state, question) => { state.questions = state.questions.filter(item => item.uid !== question.uid); state.questions.push(question) },
    DELETE_QUESTION:(state, question) => { state.questions = state.questions.filter(item => item.uid !== question.uid)},

    /** Responses */
    SET_RESPONSES: (state, list) => { state.responses =  list },
    ADD_RESPONSE: (state, response) => { state.responses.push(response) },
    UPDATE_RESPONSE: (state, response) => { state.responses = state.responses.filter(item => item.uid !== response.uid); state.responses.push(response) },
    DELETE_RESPONSE:(state, response) => { state.responses = state.responses.filter(item => item.uid !== response.uid)},


    /** Anamneses */
    SET_ANAMNESES: (state, list) => { state.anamneses = list },
    ADD_ANAMNESE: (state, an) => { state.anamneses.push(an) },
    UPDATE_ANAMNESE: (state, an) => { state.anamneses = state.anamneses.filter(item =>  item.uid !== an.uid); state.anamneses.push(an) },
    DELETE_ANAMNESE: (state, an) => { state.anamneses = state.anamneses.filter(item =>  item.uid !== an.uid)},

    SET_SECTION_INDEX: (state, index) => {
        state.indexQuestionnaire = index
    },

    SET_REVEIW_EXAMEN: (state, review) => {
        state.reviewExamen = review
    },
    ADD_REVIEW_EXAMEN: (state, review)=> {
        state.reviewExamen.push(review)
    },
    UPDATE_REVIEW_EXAMEN: (state, review) => {
        state.reviewExamen = state.reviewExamen.filter(item => item.uid !== review.uid)
        state.reviewExamen.push(review)
    },
    INIT_REVIEW_EXAMEN: (state) => {
        state.reviewExamen =  []
    },

    SET_EXAMEN_CLINIQUES: (state, list) => {
        state.examenCliniques = list
    },

    ADD_EXAMEN_CLINIQUE: (state, examen) => {
        state.examenCliniques.push(examen)
    },
    UPDATE_EXAMEN_CLINIQUE: (state, examen) => {
        state.examenCliniques = state.examenCliniques.filter(item => item.uid !== examen.uid)
        state.examenCliniques.push(examen)
    },

    DELETE_EXAMEN_CLINIQUE: (state, examen) => {
        state.examenCliniques = state.examenCliniques.filter(item => item.uid !== examen.uid)
    },
    SET_POSSIBLE_RESPONSE: (state, nb) => {state.nbPossibleResponse = nb},
    INCREASE_POSSIBLE_RESPONSE:(state) => {
        state.nbPossibleResponse ++
    },

    DECREASE_POSSIBLE_RESPONSE: (state) => {
        state.nbPossibleResponse --
    },

    SET_TYPE_ANALYSES: (state, list) => {
        state.typeAnalyses = list
    },
    ADD_TYPE_ANALYSE: (state, type) => {
        state.typeAnalyses.push(type)
    },
    UPDATE_TYPE_ANALYSE: (state, type) => {
        state.typeAnalyses = state.typeAnalyses.filter(item => item.uid !== type.uid)
        state.typeAnalyses.push(type)
    },
    DELETE_TYPE_ANALYSE: (state, type) => {
        state.typeAnalyses = state.typeAnalyses.filter(item => item.uid !== type.uid)
    },

    SET_CATEGORIE_ANALYSES: (state, list) => {
        state.categorieAnalyses = list
    },

    ADD_CATEGORIE_ANALYSE: (state, categorie) => {
        state.categorieAnalyses.push(categorie)
    },
    UPDATE_CATEGORIE_ANALYSE: (state, categorie) => {
        state.categorieAnalyses = state.categorieAnalyses.filter(item => item.uid !== categorie.uid)
        state.categorieAnalyses.push(categorie)
    },
    DELETE_CATEGORIE_ANALYSE: (state, categorie) => {
        state.categorieAnalyses = state.categorieAnalyses.filter(item => item.uid !== categorie.uid)
    },

    SET_TYPE_RECHERCHES: (state, list) => {
        state.typeRecherches = list
    },
    ADD_TYPE_RECHERCHE: (state, type) => {
        state.typeRecherches.push(type)
    },
    UPDATE_TYPE_RECHERCHE: (state, type) => {
        state.typeRecherches = state.typeRecherches.filter(item => item.uid !== type.uid)
        state.typeRecherches.push(type)
    },
    DELETE_TYPE_RECHERCHE: (state, type) => {
        state.typeRecherches = state.typeRecherches.filter(item => item.uid !== type.uid)
    },

    SET_EXAMENS: (state, list) => {
        state.examens = list
    },
    ADD_EXAMEN: (state, examen) => {
        state.examens.push(examen)
    },
    UPDATE_EXAMEN: (state, examen) =>{
        state.examens = state.examens.filter(item => item.uid !== examen.uid)
        state.examens.push(examen)
    },
    DELETE_EXAMEN: (state, examen) => {
        state.examens = state.examens.filter(item => item.uid !== examen.uid)
    },



    SET_ANALYSES: (state, list) => {
        state.analyses = list
    },
    ADD_ANALYSE: (state, analyse) => {
        state.analyses.push(analyse)
    },
    UPDATE_ANALYSE: (state, analyse) =>{
        state.analyses = state.analyses.filter(item => item.uid !== analyse.uid)
        state.analyses.push(analyse)
    },
    DELETE_ANALYSE: (state, analyse) => {
        state.analyses = state.analyses.filter(item => item.uid !== analyse.uid)
    },

    SET_RESULTAT_ANALYSES: (state, list) => {
        state.resultatAnalyses = list
    },
    ADD_RESULTAT_ANALYSE: (state, resultat) => {
        state.resultatAnalyses.push(resultat)
    },
    UPDATE_RESULTAT_ANALYSE: (state, resultat) => {
        state.resultatAnalyses = state.resultatAnalyses.filter(item => item.uid !== resultat.uid)
        state.resultatAnalyses.push(resultat)
    },
    DELETE_RESULTAT_ANALYSE: (state, resultat) => { state.resultatAnalyses = state.resultatAnalyses.filter(item => item.uid !== resultat.uid)},

    SET_ANALYSE_FILES: (state, list) => { state.analyseFiles = list },

    ADD_ANALYSE_FILE: (state, file) => { state.analyseFiles.push(file) },
    REMOVE_ANALYSE_FILE: (state, file) => { state.analyseFiles = state.analyseFiles.filter(item => item.uid !== file.uid)},

    SET_TYPE_MALADIES: (state, list) => { state.typeMaladies = list },
    ADD_TYPE_MALADIE: (state, type) => { state.typeMaladies.push(type) },
    UPDATE_TYPE_MALADIE: (state, type) => {
        state.typeMaladies = state.typeMaladies.filter(item => item.uid !== type.uid)
        state.typeMaladies.push(type)
    },
    DELETE_TYPE_MALADIE: (state, type) =>{ state.typeMaladies = state.typeMaladies.filter(item => item.uid !== type.uid)},
    SET_MALADIES: (state, list) => { state.maladies = list },
    ADD_MALADIE: (state, maladie) => { state.maladies.push(maladie) },
    UPDATE_MALADIE: (state, maladie) => {
        state.maladies = state.maladies.filter(item => item.uid !== maladie.uid)
        state.maladies.push(maladie)
    },
    DELETE_MALADIE: (state, maladie) => { state.maladies = state.maladies.filter(item => item.uid !== maladie.uid) },

    SET_MEDICAMENTS: (state, list) => { state.medicaments = list},
    ADD_MEDICAMENT: (state, medicament) => { state.medicaments.push(medicament)},
    UPDATE_MEDICAMENT: (state, medicament) => {
        state.medicaments = state.medicaments.filter(item => item.uid !== medicament.uid)
        state.medicaments.push(medicament)
    },
    DELETE_MEDICAMENT: (state, medicament) => { state.medicaments = state.medicaments.filter(item => item.uid !== medicament.uid)},

    SET_FAMILLE_MEDICAMENTS: (state, list) => { state.familleMedicaments = list},
    ADD_FAMILLE_MEDICAMENT: (state, famille) => { state.familleMedicaments.push(famille)},
    UPDATE_FAMILLE_MEDICAMENT: (state, famille) => {
        state.familleMedicaments = state.familleMedicaments.filter(item => item.uid !== famille.uid)
        state.familleMedicaments.push(famille)
    },
    DELETE_FAMILLE_MEDICAMENT: (state, famille) => { state.familleMedicaments = state.familleMedicaments.filter(item => item.uid !== famille.uid)},
    
    SET_VACCINS: (state, list) => {state.vaccins = list},
    ADD_VACCIN: (state, vaccin) => {state.vaccins.push(vaccin)},
    UPDATE_VACCIN: (state, vaccin) => { state.vaccins = state.vaccins.filter(item => item.uid !== vaccin.uid); state.vaccins.push(vaccin)},
    DELETE_VACCIN: (state, vaccin) => {state.vaccins = state.vaccins.filter(item => item.uid !== vaccin.uid)},

    SET_ANTI_PARASITAIRES: (state, list) => {state.antiParasitaires = list},
    ADD_ANTI_PARASITAIRE: (state, medoc) => {state.antiParasitaires.push(medoc)},
    UPDATE_ANTI_PARASITAIRE: (state, medoc) => {state.antiParasitaires = state.antiParasitaires.filter(item => item.uid !== medoc.uid); state.antiParasitaires.push(medoc)},
    DELETE_ANTI_PARASITAIRE: (state, medoc) => {state.antiParasitaires = state.antiParasitaires.filter(item => item.uid !== medoc.uid)},

    SET_TYPE_PARASITES:(state, list) => {state.typeParasites = list},
    ADD_TYPE_PARASITE: (state, type) => {state.typeParasites.push(type)},
    UPDATE_TYPE_PARASITE: (state, type) => {state.typeParasites = state.typeParasites.filter(item => item.uid !== type.uid); state.typeParasites.push(type)},
    DELETE_TYPE_PARASITE: (state, type) => {state.typeParasites = state.typeParasites.filter(item => item.uid !== type.uid)},

    SET_VACCINATIONS: (state, list) => {state.vaccinations = list},
    ADD_VACCINATION: (state, vaccination) => {state.vaccinations.push(vaccination)},
    UPDATE_VACCINATION: (state, vaccination) => {state.vaccinations = state.vaccinations.filter(item => item.uid !== vaccination.uid); state.vaccinations.push(vaccination)},
    DELETE_VACCINATION: (state, vaccination) => {state.vaccinations = state.vaccinations.filter(item => item.uid !== vaccination.uid)},

    SET_TRAITEMENTS: (state, list) => {state.traitements = list},
    ADD_TRAITEMENT: (state, traitement) => {state.traitements.push(traitement)},
    UPDATE_TRAITEMENT: (state, traitement) => {state.traitements = state.traitements.filter(item => item.uid !== traitement.uid); state.traitements.push(traitement)},
    DELETE_TRAITEMENT: (state, traitement) => {state.traitements = state.traitements.filter(item => item.uid !== traitement.uid)},

    INCREMENT_INDEX_MEDICAMENT: (state) => { state.indexMedicaments++ },
    DECREMENT_INDEX_MEDICAMENT: (state) => { 
        if(state.indexMedicaments > 0) {
            state.traitementMedocs = state.traitementMedocs.filter(item => item.order !== state.indexMedicaments)
            state.indexMedicaments--
        }

    },
    INIT_INDEX_MEDICAMENT: (state) => {state.indexMedicaments = 0; state.traitementMedocs = []},
    ADD_TRAITEMENT_MEDOC: (state, medoc) => {state.traitementMedocs.push(medoc)},
    REMOVE_TRAITEMENT_MEDOC: (state, index) => {state.traitementMedocs = state.traitementMedocs.filter(item => item.order !== index)},
    
    SET_TYPE_PRONOSTICS: (state, list) => {state.typePronostics = list},
    ADD_TYPE_PRONOSTIC: (state, type) => {state.typePronostics.push(type)},
    UPDATE_TYPE_PRONOSTIC: (state, type) => {state.typePronostics = state.typePronostics.filter(item => item.uid !== type.uid); state.typePronostics.push(type)},
    DELETE_TYPE_PRONOSTIC: (state, type) => {state.typePronostics = state.typePronostics.filter(item => item.uid !== type.uid)},
    SET_PRONOSTICS:(state, list) => {state.pronostics = list},
    ADD_PRONOSTIC: (state, pronostic) => {state.pronostics.push(pronostic)},
    UPDATE_PRONOSTIC: (state, pronostic) => {state.pronostics = state.pronostics.filter(item => item.uid !== pronostic.uid); state.pronostics.push(pronostic)},
    DELETE_PRONOSTIC: (state, pronostic) => {state.pronostics = state.pronostics.filter(item => item.uid !== pronostic.uid)},

    SET_MOTIF_CONSULTATIONS: (state, list) => { state.motifConsultations = list},
    ADD_MOTIF_CONSULTATION: (state, motif) => { state.motifConsultations.push(motif)},
    UPDATE_MOTIF_CONSULTATION: (state, motif) => {state.motifConsultations = state.motifConsultations.filter(item => item.uid !== motif.uid); state.motifConsultations.push(motif)},
    DELETE_MOTIF_CONSULTATION: (state, motif) =>  {state.motifConsultations = state.motifConsultations.filter(item => item.uid !== motif.uid)},


    SET_TYPE_CHIRURGIES: (state, list) => { state.typeChirurgies = list},
    ADD_TYPE_CHIRURGIE: (state, type) => { state.typeChirurgies.push(type)},
    UPDATE_TYPE_CHIRURGIE: (state, type) => {state.typeChirurgies = state.typeChirurgies.filter(item => item.uid !== type.uid); state.typeChirurgies.push(type)},
    DELETE_TYPE_CHIRURGIE: (state, type) =>  {state.typeChirurgies = state.typeChirurgies.filter(item => item.uid !== type.uid)},


    SET_CHIRURGIES: (state, list) => {state.chirurgies = list},
    ADD_CHIRURGIE: (state, chirurgie) => {state.chirurgies.push(chirurgie)},
    UPDATE_CHIRURGIE: (state, chirurgie) => {state.chirurgies = state.chirurgies.filter(item => item.uid !== chirurgie.uid); state.chirurgies.push(chirurgie) },
    DELETE_CHIRURGIE: (state, chirurgie) => {state.chirurgies = state.chirurgies.filter(item => item.uid !== chirurgie.uid)},

    SET_FICHE_ANALYSES: (state, list) => {state.ficheAnalyses = list},
    ADD_FICHE_ANALYSE: (state, fiche) => {state.ficheAnalyses.push(fiche)},
    UPDATE_FICHE_ANALYSE: (state, fiche) => {state.ficheAnalyses = state.ficheAnalyses.filter(item => item.uid !== fiche.uid); state.ficheAnalyses.push(fiche)},
    DELETE_FICHE_ANALYSE: (state, fiche) => {state.ficheAnalyses = state.ficheAnalyses.filter(item => item.uid !== fiche.uid)},

    SET_TYPE_FICHE_ANALYSES: (state, list) => {state.typeFicheAnalyses = list},
    ADD_TYPE_FICHE_ANALYSE: (state, type) => {state.typeFicheAnalyses.push(type)},
    UPDATE_TYPE_FICHE_ANALYSE: (state, type) => {state.typeFicheAnalyses = state.typeFicheAnalyses.filter(item => item.uid !== type.uid); state.typeFicheAnalyses.push(type)},
    DELETE_TYPE_FICHE_ANALYSE: (state, type) => {state.typeFicheAnalyses = state.typeFicheAnalyses.filter(item => item.uid !== type.uid)},

    SET_FICHE_SECTIONS: (state, list) => {state.ficheSections = list},
    ADD_FICHE_SECTION: (state, section) => {state.ficheSections.push(section)},
    UPDATE_FICHE_SECTION: (state, section) => {state.ficheSections = state.ficheSections.filter(item => item.uid !== section.uid); state.ficheSections.push(section)},
    DELETE_FICHE_SECTION: (state, section) => {state.ficheSections = state.ficheSections.filter(item => item.uid !== section.uid)},

    SET_FICHE_PARAMETRES: (state, list) => {state.ficheParametres = list},
    ADD_FICHE_PARAMETRE: (state, param) => {state.ficheParametres.push(param)},
    UPDATE_FICHE_PARAMETRE: (state, param) => {state.ficheParametres = state.ficheParametres.filter(item => item.uid !== param.uid); state.ficheParametres.push(param)},
    DELETE_FICHE_PARAMETRE: (state, param) => {state.ficheParametres = state.ficheParametres.filter(item => item.uid !== param.uid)},

    SET_FICHE_REFERENCES: (state, list) => {state.ficheReferences = list},
    ADD_FICHE_REFERENCE: (state, ref) => {state.ficheReferences.push(ref)},
    UPDATE_FICHE_REFERENCE: (state, ref) => {state.ficheReferences = state.ficheReferences.filter(item => item.uid !== ref.uid); state.ficheReferences.push(ref)},
    DELETE_FICHE_REFERENCE: (state, ref) => {state.ficheReferences = state.ficheReferences.filter(item => item.uid !== ref.uid)},

    SET_TOILETTAGE_ACTIVITIES: (state, activities) => { state.toilettageActivities = activities },
    ADD_TOILETTAGE_ACTIVITY: (state, activity) => { state.toilettageActivities.push(activity)},
    UPDATE_TOILETTAGE_ACTIVITY: (state, activity) => {state.toilettageActivities = state.toilettageActivities.filter(item => item.uid !== activity.uid); state.toilettageActivities.push(activity)},
    DELETE_TOILETTAGE_ACTIVITY: (state, activity) => {state.toilettageActivities = state.toilettageActivities.filter(item => item.uid !== activity.uid)},

    SET_TOILETTAGES: (state, toilettages) => {state.toilettages = toilettages},
    ADD_TOILETTAGE: (state, toilettage) =>  {state.toilettages.push(toilettage)},
    UPDATE_TOILETTAGE: (state, toilettage) => {state.toilettages = state.toilettages.filter(item => item.uid !== toilettage.uid); state.toilettages.push(toilettage)},
    DELETE_TOILETTAGE: (state, toilletage) => {state.toilettages = state.toilettages.filter(item => item.uid !== toilletage.uid)}
} 

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
