
const state = {
    packs: [],
    services: [],
    modules: [],
    abonnements: [],
    renouvellements: []
}


const getters = {
    packs: (state) => state.packs,
    services: (state) => state.services,
    modules: (state) => state.modules,
    abonnements: (state) => state.abonnements,
    renouvellements: (state) => state.renouvellements 
}


const mutations = {
    SET_PACKS: (state, list) => {state.packs = list},
    ADD_PACK: (state, pack) => {state.packs.push(pack)},
    UPDATE_PACK: (state, pack) => {state.packs = state.packs.filter(item => item.uid !== pack.uid); state.packs.push(pack) },
    DELETE_PACK: (state, pack) => {state.packs = state.packs.filter(item => item.uid !== pack.uid)},

    SET_SERVICES: (state, list) => {state.services = list},
    ADD_SERVICE: (state, service) => {state.services.push(service)},
    UPDATE_SERVICE: (state, service) => {state.services = state.services.filter(item => item.uid !== service.uid); state.services.push(service)},
    DELETE_SERVICE: (state, service) => {state.services = state.services.filter(item => item.uid !== service.uid)},

    SET_MODULES: (state, list) => {state.modules = list},
    ADD_MODULE: (state, module) => {state.modules.push(module)},
    UPDATE_MODULE: (state, module) => {state.modules = state.modules.filter(item => item.uid !== module.uid); state.modules.push(module)},
    DELETE_MODULE: (state, module) => {state.modules = state.modules.filter(item => item.uid !== module.uid)},

    SET_ABONNEMENTS: (state, list) => {state.abonnements = list},
    ADD_ABONNEMENT: (state, abonnement) => {state.abonnements.pusht(abonnement)},
    UPDATE_ABONNEMENT: (state, ab) => {state.abonnements = state.abonnements.filter(item => item.uid !== ab.uid); state.abonnements.push(ab)},
    DELETE_ABONNEMENT: (state, ab) => {state.abonnements = state.abonnements.filter(item => item.uid !== ab.uid)},


    SET_RENOUVELLEMENTS: (state, list) => {state.renouvellements = list},
    ADD_RENOUVELLEMENT: (state, r) => {state.renouvellements.push(r)},
    UPDATE_RENOUVELLEMENT: (state, r) => {state.renouvellements = state.renouvellements.filter(item => item.uid !==  r.uid); state.renouvellements.push(r)},
    DELETE_RENOUVELLEMENT: (state, r) => {state.renouvellements = state.renouvellements.filter(item => item.uid !== r.uid)}

    
}



export default {
    namespaced: true,
    state,
    getters,
    mutations
}