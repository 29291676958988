const state = {
    types: [],
    events: [],
    timeSlots: [],
    appointments: [],
    selectedEventUid: null,
}


const getters = {
    types: state => state.types,
    events: state => state.events,
    timeSlots: state => state.timeSlots,
    appointments: state => state.appointments,
    selectedEventUid: state => state.selectedEventUid
}

const mutations = {
    SET_TYPES: (state, list) => {state.types  = list},
    ADD_TYPE: (state, type) => {state.types.push(type)},
    UPDATE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid); state.types.push(type)},
    DELETE_TYPE: (state, type) => {state.types = state.types.filter(item => item.uid !== type.uid)},

    SET_EVENTS: (state, list) => {state.events = list},
    ADD_EVENT: (state, event) => {state.events.push(event)},
    UPDATE_EVENT: (state, event) => {state.events = state.events.filter(item =>item.uid !== event.uid); state.events.push(event)},
    DELETE_EVENT: (state, event) => {state.events = state.events.filter(item => item.uid !== event.uid)},

    SET_SELECTED_EVENT_UID: (state, uid) => {state.selectedEventUid = uid},

    SET_TIME_SLOTS: (state, list) => {state.timeSlots = list},
    ADD_TIME_SLOT: (state, slot) => {state.timeSlots.push(slot)},
    UPDATE_TIME_SLOT: (state, slot) => {state.timeSlots = state.timeSlots.filter(item => item.uid !== slot.uid); state.timeSlots.push(slot)},
    DELETE_TIME_SLOT: (state, slot) => {state.timeSlots = state.timeSlots.filter(item => item.uid !== slot.uid)},

    SET_APPOINTMENTS: (state, list) => {state.appointments = list},
    ADD_APPOINTMENT: (state, ap) => {state.appointments.push(ap)},
    UPDATE_APPOINTMENT: (state, ap) => {state.appointments = state.appointments.filter(item => item.uid !== ap.uid); state.appointments.push(ap)},
    DELETE_APPOINTMENT: (state, ap) => {state.appointments = state.appointments.filter(item => item.uid !== ap.uid)}

}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}