import gql from 'graphql-tag'

export const ADD_TYPE_PRODUCT = gql`
mutation Mutation($type: MarketTypeObjectInput!){
    addMarketTypeObject(type: $type)
}
`
export const UPDATE_TYPE_PRODUCT = gql`
  mutation Mutation($uid: String!, $type: MarketTypeObjectInput!){
    updteMarketTypeObject(uid: $uid, type: $type)
  }
`
export const DELETE_TYPE_PRODUCT = gql`
mutation Mutation($uid: String!) {
    deleteMarketTypeObject(uid: $uid)
}
`

export const SUBSCRIBER_TYPE_PRODUCT = gql`
subscription Subscription {
    market_type_object {
        type
        data {
            uid
            libelle
            description
            code
        }
    }
}
`


export const ADD_CATEGORIE = gql`
mutation Mutation( $libelle: String!, $type: String!){
    addMarketCategorie( libelle: $libelle, type: $type)
}
`
export const UPDATE_CATEGORIE = gql`
  mutation Mutation($uid: String!, $libelle: String!, $type: String!){
    updateMarketCategorie(uid: $uid, libelle: $libelle, type: $type)
  }
`
export const DELETE_CATEGORIE = gql`
mutation Mutation($uid: String!) {
    deleteMarketCategorie(uid: $uid)
}
`

export const SUBSCRIBER_CATEGORIE = gql`
subscription Subscription {
    market_categorie {
    type
    data {
        uid
        libelle
        type
    }
  }
}
`


export const ADD_FILTRE = gql`
mutation Mutation( $libelle: String!, $type: String!){
    addMarketFiltre( libelle: $libelle, type: $type)
}
`
export const UPDATE_FILTRE = gql`
  mutation Mutation($uid: String!, $libelle: String!, $type: String!){
    updateMarketFiltre(uid: $uid, libelle: $libelle, type: $type)
  }
`
export const DELETE_FILTRE = gql`
mutation Mutation($uid: String!) {
    deleteMarketFiltre(uid: $uid)
}
`

export const SUBSCRIBER_FILTRE = gql`
subscription Subscription {
  market_filtre {
    type
    data {
        uid
        libelle
        type
    }
  }
}
`

export const LOAD_PRODUCTS = gql`
query loadProduts($type: String, $categorie: String, $tags: String) {
  marketProducts(type: $type, categorie: $categorie, tags: $tags) {
    uid
    libelle
    description
    type
    categorie
    tags
    photos
    actualAmount
    activate
    quantity
    createBy
    createdAt
  }
}

`


export const ADD_PRODUCT = gql`
mutation Mutation( $product: MarketProductInput!){
  addProduct( product: $product)
}
`

export const UPDATE_PRODUCT = gql`
mutation Mutation($uid: String!,  $product: MarketProductUpdate!){
  updateProduct(uid: $uid, product: $product)
}
`

export const DELETE_PRODUCT = gql`
mutation Mutation($uid: String!) {
  deleteProduct(uid: $uid)
}
`

export const SET_PRODUCT_STATE = gql`
mutation Mutation($uid: String!, $state: Boolean!) {
  setProductState(uid: $uid, state: $state)
}
`

export const ADD_PRODUCT_PHOTO = gql`
mutation AddProductPhoto($uid: String!, $photo: String!) {
  addProductPhoto(uid: $uid, photo: $photo)
}
`
export const DELETE_PRODUCT_PHOTO = gql`
mutation DeleteProductPhoto($uid: String!, $photo: String!) {
  deleteProductPhoto(uid: $uid, photo: $photo)
}
`

export const SUBSCRIBER_PRODUCT = gql`
subscription Subscription {
  market_product {
    type
    data {
      uid
      libelle
      description
      type
      categorie
      tags
      photos
      actualAmount
      activate
      quantity
      createBy
      createdAt
    }
  }
}
`

export const ADD_PRODUCT_PRICE = gql`
mutation Mutation( $price: MarketProductPriceInput!){
  addProductPrice( price: $price)
}
`
export const DELETE_PRODUCT_PRICE = gql`
mutation Mutation( $uid: String!){
  deleteProductPrice( uid: $uid)
}
`

export const SUBSCRIBER_MARKET_PRODUCT_PRICE = gql`
subscription Subscription {
  market_product_price {
    type
    data {
      uid
      amount
      product
      actual
      principal
      discount
      createdAt
      createBy
    }
  }
}
`


export const ADD_FACTURE = gql`
mutation Mutation($facture: FactureInput!){
  addFacture(facture: $facture)
}
`
export const UPDATE_FACTURE = gql`
  mutation Mutation($uid: String!, $facture: FactureInput!){
    updateFacture(uid: $uid, facture: $facture)
  }
`
export const DELETE_FACTURE = gql`
mutation Mutation($uid: String!) {
  deleteFacture(uid: $uid)
}
`

export const VALIDATE_FACTURE = gql`
mutation Mutation($uid: String!) {
  validateFacture(uid: $uid)
}
`

export const SEND_FACTURE = gql`
mutation Mutation($uid: String!) {
  sendFacture(uid: $uid)
}
`

export const RESEND_FACTURE = gql`
mutation Mutation($uid: String!) {
  resendFacture(uid: $uid)
}
`

export const SUBSCRIBER_FACTURE= gql`
subscription Subscription {
  facture {
        type
        data {
            uid
            user
            amount
            invoiceNumber
            status
            description
            reference
            createdAt
            createBy
        }
    }
}
`


export const ADD_FACTURE_ITEM = gql`
mutation Mutation($item: FactureItemInput!){
  addFactureItem(item: $item)
}
`

export const DELETE_FACTURE_ITEM = gql`
mutation Mutation($uid: String!) {
  deleteFactureItem(uid: $uid)
}
`

export const SUBSCRIBER_FACTURE_ITEM= gql`
subscription Subscription {
  facture_item {
        type
        data {
            uid
            facture
            libelle
            quantity
            unitPrice
            createdAt
        }
    }
}
`