
const state = {
    aliments: [],
    animaux: [],
    especes: [],
    typeRobes: [],
    robes:  [],
    races: [],
    selectedAnimal: null,
    constantes: [],
    currentConstante: null,
    actionsPostMortem: [],
    motifDeces: [],
    declarationDeces: [],
    currentActionsPostMortem: []
}

const getters = {
    aliments: state => state.aliments,
    animaux: state => state.animaux,
    especes: state => state.especes,
    activeEspeces: state => [...state.especes].filter(item => item.active),
    typeRobes: state => state.typeRobes, 
    robes : state => state.robes,
    races: state => state.races,
    selectedAnimal: state => state.selectedAnimal,
    constantes: state => state.constantes,
    currentConstante: state => state.currentConstante,
    actionsPostMortem: state => state.actionsPostMortem,
    motifDeces: state => state.motifDeces,
    declarationDeces: state => state.declarationDeces,
    currentActionsPostMortem: state => state.currentActionsPostMortem
}

const actions = {


}

const mutations = {
    /** Alimentation */
    SET_ALIMENTS: (state, list) => { state.aliments =  list },
    ADD_ALIMENT: (state, al) => { state.aliments.push(al) },
    UPDATE_ALIMENT: (state, al) => { state.aliments = state.aliments.filter(item => item.uid !== al.uid); state.aliments.push(al)},
    DELETE_ALIMENT: (state, al) => { state.aliments = state.aliments.filter(item => item.uid !== al.uid)},
    
    /** ANIMAUX */
    SET_ANIMAUX: (state, list) => {
        state.animaux = list
    },
    ADD_ANIMAL: (state, animal) => {
        state.animaux.push(animal)
    },
    UPDATE_ANIMAL: (state, animal) => {
        state.animaux = state.animaux.filter(item => item.uid !== animal.uid)
        state.animaux.push(animal)
    },
    DELETE_ANIMAL: (state, animal) => {
        state.animaux = state.animaux.filter(item => item.uid !== animal.uid)
    },

    SET_SELECTED_ANIMAL: (state, uid) => {
        state.selectedAnimal =  uid
    },

    SET_ESPECES: (state, list) => {
        state.especes = list
    },
    ADD_ESPECE: (state, espece) => {
        state.especes.push(espece)
    },
    UPDATE_ESPECE: (state, espece) => {
        state.especes = state.especes.filter(item => item.uid !== espece.uid)
        state.especes.push(espece)
    },
    DELETE_ESPECE: (state, espece) => {
        state.especes = state.especes.filter(item => item.uid !== espece.uid)
    },
    
    SET_TYPE_ROBES: (state, list) => {
        state.typeRobes = list
    },
    ADD_TYPE_ROBE: (state, type) => {
        state.typeRobes.push(type)
    },
    UPDATE_TYPE_ROBE: (state, type) => {
        state.typeRobes = state.typeRobes.filter(item => item.uid !== type.uid)
        state.typeRobes.push(type)
    },
    DELETE_TYPE_ROBE: (state, type) => {
        state.typeRobes = state.typeRobes.filter(item => item.uid !== type.uid)
    },


    SET_ROBES: (state, list) => {
        state.robes = list
    },
    ADD_ROBE: (state, robe) => {
        state.robes.push(robe)
    },
    UPDATE_ROBE: (state, robe) => {
        state.robes = state.robes.filter(item => item.uid !== robe.uid)
        state.robes.push(robe)
    },
    DELETE_ROBE: (state, robe) =>  {
        state.robes = state.robes.filter(item => item.uid !== robe.uid)
    },

    SET_RACES: (state, list) => {
        state.races =  list
    },
    ADD_RACE: (state, race) => {
        state.races.push(race)
    },
    UPDATE_RACE: (state, race) => {
        state.races = state.races.filter(item => item.uid !== race.uid)
        state.races.push(race)
    },
    DELETE_RACE: (state, race) => {
        state.races = state.races.filter(item => item.uid !== race.uid)
    },

    SET_CONSTANTES:(state, list) => {state.constantes = list},
    ADD_CONSTANTE: (state, constante) => {state.constantes.push(constante)},
    UPDATE_CONSTANTE: (state, constante) => {state.constantes = state.constantes.filter(item => item.uid !== constante.uid); state.constantes.push(constante)},
    DELETE_CONSTANTE: (state, constante) => {state.constantes = state.constantes.filter(item => item.uid !== constante.uid)},
    SET_CURRENT_CONSTANTE: (state, constante) => {state.currentConstante = constante},

    SET_ACTIONS_POST_MORTEM: (state, list) => {state.actionsPostMortem = list},
    ADD_ACTION_POST_MORTEM: (state, action) => {state.actionsPostMortem.push(action)},
    UPDATE_ACTION_POST_MORTEM: (state, action) => {state.actionsPostMortem = state.actionsPostMortem.filter(item => item.uid !== action.uid); state.actionsPostMortem.push(action)},
    DELETE_ACTION_POST_MORTEM: (state, action) => {state.actionsPostMortem = state.actionsPostMortem.filter(item => item.uid !== action.uid)},

    SET_MOTIF_DECES: (state, list) => {state.motifDeces = list},
    ADD_MOTIF_DECES: (state, motif) => {state.motifDeces.push(motif)},
    UPDATE_MOTIF_DECES: (state, motif) => {state.motifDeces = state.motifDeces.filter(item => item.uid !== motif.uid); state.motifDeces.push(motif)},
    DELETE_MOTIF_DECES: (state, motif) => {state.motifDeces = state.motifDeces.filter(item => item.uid !== motif.uid)},


    SET_DECLARATION_DECES: (state, list) => {state.declarationDeces = list},
    ADD_DECLARATION_DECES: (state, declaration) => {state.declarationDeces.push(declaration)},
    UPDATE_DECLARATION_DECES: (state, declaration) => {state.declarationDeces = state.declarationDeces.filter(item => item.uid !== declaration.uid); state.declarationDeces.push(declaration)},
    DELETE_DECLARATION_DECES: (state, declaration) => {state.declarationDeces = state.declarationDeces.filter(item => item.uid !== declaration.uid)},

    INIT_CURRENT_ACTIONS_POST_MORTEM: (state) => {state.currentActionsPostMortem = []},
    UPDATE_CURRENT_ACTIONS_POST_MORTEM: (state, action) => {state.currentActionsPostMortem = state.currentActionsPostMortem.filter(item => item.order !== action.order); state.currentActionsPostMortem.push(action)},
    DELETE_CURRENT_ACTIONS_POST_MORTEM:(state, index) => {state.currentActionsPostMortem = state.currentActionsPostMortem.filter(item => item.order !== index)}
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }