import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password){
    uid
    token
    expireAt
  }
}
`

export const CHECK_USERNAME = gql`
mutation Mutation($email: String!) {
  checkUser(email: $email) {
    uid
    firstConnexion
    active
  }
}
`

export const SET_PASSWORD = gql`
mutation Mutation($password: String!, $uid: String!) {
  setPassword(password: $password, uid: $uid){
    uid
    token
    expireAt
  }
}
`

export const VALIDATE_ACTIVATION_CODE = gql`
mutation Mutation($email: String!, $codeActivation: Int!) {
  validateCode(email: $email, codeActivation: $codeActivation)
}
`

export const REGISTRATION = gql`
mutation Mutation($fullname: String!, $telephone: String!, $typeCompte: String!, $email: String!, $address: String) {
  register(fullname: $fullname, telephone: $telephone, typeCompte: $typeCompte, email: $email, address: $address)
}
`

export const DELETE_USER = gql`
mutation Mutation($uid: String!) {
  deleteAccount(uid: $uid) 
}
`

export const EDIT_USER = gql`
  mutation Mutation($uid: String!, $updateUser: UserUpdateInput!){
    updateUser(uid: $uid, updateUser: $updateUser)
  }
`

export const ADD_TYPE_COMPTE = gql`
mutation Mutation($type: TypeCompteInput!){
  addTypeCompte(type: $type)
}
`
export const UPDATE_TYPE_COMPTE = gql`
  mutation Mutation($uid: String!, $type: TypeCompteInput!){
    updateTypeCompte(uid: $uid, type: $type)
  }
`
export const DELETE_TYPE_COMPTE = gql`
mutation Mutation($uid: String!) {
  deleteTypeCompte(uid: $uid)
}
`

export const SUBSCRIBER_TYPE_COMPTE = gql`
subscription Subscription {
  type_compte {
    type
    data {
      uid
      libelle
      code
    }
  }
}
`

export const SUBSCRIBER_USER = gql`
subscription Subscription {
  users {
    type
    data {
      uid
      fullname
      username
      email
      telephone
      address
      lastConnexion
      typeCompte
      firstConnexion
      active
      photo
    }
  }
}
`

export const SUBSCRIBE_SMS = gql`
  subscription Subscription{
    sms {
      type
      data {
          uid
          messageId
          number
          message
          type
          route
          smsCount
          amount
          currency
          status
          createdAt
      }
    }
  }
`

export const ADD_DIRECT_SMS = gql`
mutation Mutation($message: DirectSmsInput!){
  sendDirectSms(message: $message)
}
`
export const DELETE_DIRECT_SMS = gql`
mutation Mutation($uid: String!) {
  deleteDirectSms(uid: $uid)
}
`
export const SUBSCRIBE_DIRECT_SMS = gql`
  subscription Subscription{
    direct_sms {
      type
      data {
          uid
          destinataire
          message
          type
          startDate
          endDate
          planify
          createBy
          createdAt
      }
    }
  }
`


export const SET_NOTIFICATION_AS_READ = gql`
mutation Mutation($uid: String!) {
  setNotificationAsRead(uid: $uid)
} 
`