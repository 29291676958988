<template>
  <div>
    <li class="menu">
      <router-link
        :to="{name: 'animaux'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-dog" />
          <span>Animaux</span>
        </div>
      </router-link>
    </li>

    <li class="menu">
      <router-link
        :to="{name: 'declaration-deces'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-skull-crossbones" />
          <span>Déclaration décès</span>
        </div>
      </router-link>
    </li>
  </div>
</template>

<script>
import { ADD, DELETE, UPDATE } from '../../../constants/app'
import { SUBSCRIBE_ACTION_POST_MORTEM, SUBSCRIBE_ALIMENTATION, SUBSCRIBE_ANIMAL, SUBSCRIBE_CONSTANTE, SUBSCRIBE_ESPECE, SUBSCRIBE_MOTIF_DECES, SUBSCRIBE_RACE, SUBSCRIBE_ROBE, SUBSCRIBE_TYPE_ROBE } from '../../../graphql/identification'
import {mapGetters, mapMutations} from 'vuex'
export default {

    data(){
        return {

        }
    },
    methods: {
        ...mapMutations({
            addEspece: 'identification/ADD_ESPECE',
            updateEspece: 'identification/UPDATE_ESPECE',
            deleteEspece: 'identification/DELETE_ESPECE',

            addRace: 'identification/ADD_RACE',
            updateRace: 'identification/UPDATE_RACE',
            deleteRace: 'identification/DELETE_RACE',

            addTypeRobe: 'identification/ADD_TYPE_ROBE',
            updateTypeRobe: 'identification/UPDATE_TYPE_ROBE',
            deleteTypeRobe: 'identification/DELETE_TYPE_ROBE',
            
            addRobe: 'identification/ADD_ROBE',
            updateRobe: 'identification/UPDATE_ROBE',
            deleteRobe: 'identification/DELETE_ROBE',

            addAliment: 'identification/ADD_ALIMENT',
            updateAliment: 'identification/UPDATE_ALIMENT',
            deleteAliment: 'identification/DELETE_ALIMENT',

            addConstante: 'identification/ADD_CONSTANTE',
            updateConstante: 'identification/UPDATE_CONSTANTE',
            deleteConstante: 'identification/DELETE_CONSTANTE',

            addAnimal: 'identification/ADD_ANIMAL',
            updateAnimal: 'identification/UPDATE_ANIMAL',
            deleteAnimal: 'identification/DELETE_ANIMAL',

            addMotifDeces: 'identification/ADD_MOTIF_DECES',
            updateMotifDeces: 'identification/UPDATE_MOTIF_DECES',
            deleteMotifDeces: 'identification/DELETE_MOTIF_DECES',

            addActionPostMortem: 'identification/ADD_ACTION_POST_MORTEM',
            updateActionPostMortem: 'identification/UPDATE_ACTION_POST_MORTEM',
            deleteActionPostMortem: 'identification/DELETE_ACTION_POST_MORTEM'
        }),

    },
    apollo: {
        $subscribe: {
            espece: {
                query: SUBSCRIBE_ESPECE ,
                result({data}){
                    let e = data.espece
                    if(e.type === ADD) this.addEspece(e.data)
                    if(e.type === DELETE) this.deleteEspece(e.data)
                    if(e.type === UPDATE) this.updateEspece(e.data)
                }
            },
            race: {
                query: SUBSCRIBE_RACE ,
                result({data}){
                    let r = data.race
                    if(r.type === ADD) this.addRace(r.data)
                    if(r.type === DELETE) this.deleteRace(r.data)
                    if(r.type === UPDATE) this.updateRace(r.data)
                }
            },
            typerobe: {
                query: SUBSCRIBE_TYPE_ROBE ,
                result({data}){
                    let t = data.typerobe
                    if(t.type === ADD) this.addTypeRobe(t.data)
                    if(t.type === DELETE) this.deleteTypeRobe(t.data)
                    if(t.type === UPDATE) this.updateTypeRobe(t.data)
                }
            },
            robe: {
                query: SUBSCRIBE_ROBE ,
                result({data}){
                    let r = data.robe
                    if(r.type === ADD) this.addRobe(r.data)
                    if(r.type === DELETE) this.deleteRobe(r.data)
                    if(r.type === UPDATE) this.updateRobe(r.data)
                }
            },
            alimentation: {
                query: SUBSCRIBE_ALIMENTATION,
                result({data}) {
                    let a = data.alimentation
                    if(a.type === ADD) this.addAliment(a.data)
                    if(a.type === DELETE) this.deleteAliment(a.data)
                    if(a.type === UPDATE) this.updateAliment(a.data)
                }
            },
            constante: {
                query: SUBSCRIBE_CONSTANTE,
                result({data}) {
                    let c = data.constante
                    if(c.type === ADD) this.addConstante(c.data)
                    if(c.type === DELETE) this.deleteConstante(c.data)
                    if(c.type === UPDATE) this.updateConstante(c.data)
                }
            },
            animal: {
                query: SUBSCRIBE_ANIMAL,
                result({data}) {
                    let c = data.animal
                    if(c.type === ADD) this.addAnimal(c.data)
                    if(c.type === DELETE) this.deleteAnimal(c.data)
                    if(c.type === UPDATE) this.updateAnimal(c.data)
                }
            },
            motif_deces: {
                query: SUBSCRIBE_MOTIF_DECES,
                result({data}) {
                    let c = data.motif_deces
                    if(c.type === ADD) this.addMotifDeces(c.data)
                    if(c.type === DELETE) this.deleteMotifDeces(c.data)
                    if(c.type === UPDATE) this.updateMotifDeces(c.data)
                }
            },
            action_post_mortem: {
                query: SUBSCRIBE_ACTION_POST_MORTEM,
                result({data}) {
                    let c = data.action_post_mortem
                    if(c.type === ADD) this.addActionPostMortem(c.data)
                    if(c.type === DELETE) this.deleteActionPostMortem(c.data)
                    if(c.type === UPDATE) this.updateActionPostMortem(c.data)
                }
            },
        }
    },
    computed: {
        ...mapGetters({
            is_admin: 'auth/is_admin',
            is_super_admin: 'auth/is_super_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
        })
    }
}
</script>

<style>

</style>