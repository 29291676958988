import gql from 'graphql-tag'

export const INIT_DATA = gql`
    query Query {
        me {
          uid
          email
          fullname
          telephone
          typeCompte
          lastConnexion
          firstConnexion
          photo
        }
        users {
          uid
          fullname
          username
          email
          telephone
          address
          lastConnexion
          typeCompte
          firstConnexion
          active
          photo
        }
        groupes {
          uid
          name
          description
          createBy
          createdAt
          members {
            user
          }
        }
        groupeParams{
          uid
          groupe
          type
          value
        }
        sms{
          uid
          messageId
          number
          message
          type
          route
          smsCount
          amount
          currency
          status
          createdAt
        }
        directSms {
          uid
          destinataire
          message
          type
          startDate
          endDate
          planify
          createBy
          createdAt
        }
        especes {
            uid
            libelle
            code
            active
        }
        races {
          uid
          libelle
          code
          espece
        }
        typeRobes {
          uid
          libelle
        }
        robes {
          uid
          libelle
          code
          espece
          type
        }
        typeRobes {
          uid
          libelle
        }
      typeComptes {
          uid
          libelle
          code
        }

        animaux {
          uid
          codeIso
          espece
          robe
          race
          proprio
          sexe
          signe
          dateNaissance
          active
          name
          photo
          createdAt
          parrain
        }

        modevies {
          uid
          libelle
        }
        alimentations {
          uid
          libelle
        }
        sections {
          libelle
          uid
          ordre
        }
        questions {
          uid
          libelle
          ordre
          section
          code
          multiResponse
          libre
          sexe
        }
        responses {
          uid
          libelle
          question
          ordre
        }
        anamneses {
          uid
          animal
          vaccine
          vermifuge
          motif
          duration
          symptome
          traitement
          antecedant
          alimentation
          modeVie
          createdAt
          createBy
          operationDate
        }

        examencliniques {
          uid
          animal
          createBy
          detail
          createdAt,
          anamnese,
          analyseDif
        }
        typeAnalyses {
          uid
          libelle
          hasCategorie
        }
        categorieAnalyses {
          uid
          libelle
          hasRecherche
          typeAnalyse
        }
        typeRecherches {
          uid
          libelle
          hasExamen
          categorieAnalyse
          hasFiche
          fiche
        }
        examens {
          uid
          libelle
          typeRecherche
          montant
          hasFiche
          fiche
        }

        analyses {
          uid
          animal
          createBy
          type
          categorie
          createdAt
          typeRecherche
          examen
          infoSupplementaire
          operationDate
        }
        resultatAnalyses {
          uid
          analyse
          createBy
          createdAt
          conclusion
          files
        }
        resultatAnalyseFiches{
          uid
          analyse
          createBy
          createdAt
          data
          commentaire
          info
        }
        typeMaladies {
          uid
          libelle
        }
        maladies{
          uid
          libelle
          type
        }
        familleMedicaments{
          uid
          libelle
        }
        medicaments{
          uid
          libelle
          famille
        }
        typeParasites{
          uid
          libelle
        }
        antiParasitaires {
          uid
          libelle
          type
        }
        vaccins{
          espece
          uid
          libelle
          maladie
          valence
          primo
          annuel
        }
        vaccinations {
          uid
          animal
          maladie
          vaccin
          info
          createBy
          createdAt
          dateRappel
          dateVaccination
        }
        traitements{
          uid
          maladie
          animal
          medicaments
          info
          createBy
          createdAt
        }
        typePronostics {
          uid
          libelle
        }
        pronostics{
          uid
          animal
          type
          createBy
          createdAt
        }
        constantes{
          uid
          animal
          poid
          temperature
          fr
          fc
          createBy
          createdAt
          trc
          operationDate
        }
        motifConsultations{
          uid
          libelle
        }
        typeChirurgies{
          uid
          libelle
          faible
        }
        
        chirurgies{
          uid
          type
          animal
          hospitalisation
          duration
          info
          createBy
          createdAt
        }

        ficheAnalyses {
          uid
          libelle
          categorieAnalyse
        }
        typeFicheAnalyses{
          uid
          libelle
          code
        }
        sectionFiches {
          libelle
          uid
          fiche
        }
        parametreFiches {
          libelle
          fiche
          uid
          section
        }
        referenceFiches {
          uid
          valeur
          espece
          parametre
        }
        toilettageActivities{
          uid
          libelle
          prix
          currency
        }
        toilettages{
          uid
          animal
          activities
          info
          createBy
          createdAt
          dateToilettage
          dateRappel
          prix
          currency
        }
        motifDeces {
          uid
          libelle
          code
        }
        actionsPostMortem{
          uid
          libelle
          code
          prix
        }
        declarationDeces{
          uid
          motif
          animal
          info
          createBy
          createdAt
          dateDeces
          actionsPostMortem
        }
        notifications{
          uid
          type
          libelle
          description
          read
          files
          createdAt
          objet
        }
        typeEvents{
          uid
          libelle
          description
          label
          code
        }
        events{
          uid
          type
          libelle
          allDay
          accomplished
          startDate
          endDate
          description
          infoUtile
          private
          createBy
          createdAt
        }
        timeSlots {
          uid
          dayOfWeek
          startTime
          active
          createdAt
        }
        appointments {
          uid
          timeslot
          user
          animal
          subject
          date
          status
          complement
          event
          createdAt
        }

        allProducts{
          uid
          libelle
          description
          type
          categorie
          tags
          photos
          actualAmount
          activate
          quantity
          createBy
          createdAt
        }
        marketTypeObjects{
          uid
          libelle
          code
          description
        }
        marketCategories{
          uid
          libelle
          type
        }
        marketFiltres{
          uid
          libelle
          type
        }
        marketProductPrices{
          uid
          amount
          product
          actual
          principal
          discount
          createdAt
          createBy
        }
        marketOrders{
          uid
          user
          amount
          orderNumber
          details
          status
          createdAt
        }
        factures{
          uid
          user
          amount
          invoiceNumber
          status
          description
          reference
          createdAt
          createBy
        }
        factureItems{
          uid
          facture
          libelle
          quantity
          unitPrice
          createBy
          createdAt
        }

        packServices{
          uid
          libelle
          code
          createBy
          createdAt
        }
        packs{
          uid
          libelle
          code
          description
          services
          amount
          agent
          photo
          createBy
          createdAt
        }
        packModules{
          uid
          libelle
          code
          services
          amount
          photo
        }
        packAbonnements{
          uid
          animal
          user
          pack
          modules
          amount
          lastRenew
          actif
          createdAt
        }
        packReviewHistory{
          uid
          abonnement
          user
          amount
          createdAt
        }
    }
`