const state = {
  item: { currentPage: 1, itemsPerPage: 10, sortName: 'publish_time', tagId: '' },
  params: {},
  tmpUploadFiles: []
}

const getters = {
  params: state => state.params,
  item: state => state.item,
  itemsPerpage: state => state.item.itemsPerPage,
  tmpUploadFiles: state => state.tmpUploadFiles
}

const actions = {
  changeOptions ({ commit }, item) {
    commit('CHANGE_OPTIONS', item)
  },
  changePerpage ({ commit }, perpage) {
    commit('CHANGE_ITEMS_PERPAGE', perpage)
  },
  setParams ({ commit }, params) {
    commit('SET_PARAMS', params)
  }

}

const mutations = {
  CHANGE_OPTIONS: (state, item) => {
    state.item = item
  },
  CHANGE_ITEMS_PERPAGE: (state, perpage) => {
    state.item.itemsPerPage = perpage
  },
  SET_PARAMS: (state, params) => {
    state.params = params
  },
  SET_TMP_UPLOAD_FILES: (state, list) => {
    state.tmpUploadFiles = list
  },
  ADD_TMP_UPLOAD_FILES: (state, file) => {
    state.tmpUploadFiles.push(file)
  },
  REMOVE_TMP_UPLOAD_FILE: (state, file) => {
    state.tmpUploadFiles = state.tmpUploadFiles.filter(item => item._id!== file._id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
