const load  = (component) => {
    return () => import(`../views/${component}.vue`)
  }

  export default [
    {
        path: '/sanitaire/pronostic',
        name: 'pronostic',
        component: load('Sanitaire/pronostic/Index')
    },
    {
        path: '/sanitaire/vaccination',
        name: 'vaccination',
        component: load('Sanitaire/vaccination/Index')
    },
    {
        path: '/sanitaire/vaccination/:uid',
        name: 'detail_vaccination',
        component: load('Sanitaire/vaccination/Detail')
    },
    {
        path: '/sanitaire/add-vaccination',
        name: 'add-vaccination',
        component: load('Sanitaire/vaccination/Add')
    },
    {
        path: '/sanitaire/veille-sanitaire',
        name: 'veille-sanitaire',
        component: load('Sanitaire/vaccination/VeilleSanitaire')
    },
    {
        path: '/sanitaire/traitement',
        name: 'traitement',
        component: load('Sanitaire/Traitement/Index')
    },
    {
        path: '/sanitaire/traitement/detail/:uid',
        name: 'detail_traitement',
        component: load('Sanitaire/Traitement/Detail')
    },
    {
        path: '/sanitaire/anamnese',
        name: 'anamnese',
        component: load('Sanitaire/anamnese/Index')
    },
    {
        path: '/sanitaire/anamnese/add',
        name: 'add_anamnese',
        component: load('Sanitaire/anamnese/Add')
    },
    {
        path: '/sanitaire/anamnese/add/:uid',
        name: 'add_anamnese_uid',
        component: load('Sanitaire/anamnese/Add')
    },
    {
        path: '/sanitaire/anamnese/detail/:uid',
        name: 'detail_anamnese',
        component: load('Sanitaire/anamnese/Detail')
    },
    {
        path: '/sanitaire/anamnese/update/:uid',
        name: 'update_anamnese',
        component: load('Sanitaire/anamnese/Edit')
    },
    {
        path: '/sanitaire/examen-clinique',
        name: 'examen_clinique',
        component: load('Sanitaire/examenClinique/Index')
    },
    {
        path: '/sanitaire/examen-clinique/add',
        name: 'add_examen_clinique',
        component: load('Sanitaire/examenClinique/Add')
    },
    {
        path: '/sanitaire/examen-clinique/:uid',
        name: 'detail_examen_clinique',
        component: load('Sanitaire/examenClinique/Detail')
    },
    {
        path: '/sanitaire/analyse',
        name: 'analyses',
        component: load('Sanitaire/analyse/Index')
    },
    {
        path: '/sanitaire/analyse/add',
        name: 'add-analyse',
        component: load('Sanitaire/analyse/Add')

    },
    {
        path: '/sanitaire/analyse/:uid',
        name: 'analyse_detail',
        component: load('Sanitaire/analyse/Detail')
    },
    {
        path: '/sanitaire/analyse/:uid/modifier',
        name: 'analyse_update',
        component: load('Sanitaire/analyse/Edit')
    },
    {
        path: '/sanitaire/analyse/:uid/add-resultat',
        name: 'analyse_add_result',
        component: load('Sanitaire/analyse/AddResultat')
    },
    {
        path: '/sanitaire/chirurgie',
        name: 'chirurgie',
        component: load('Sanitaire/chirurgie/Index')
    },
    {
        path: '/sanitaire/chirurgie/:uid',
        name: 'chirurgie_detail',
        component: load('Sanitaire/chirurgie/Detail')
    },

    {
        path: '/sanitaire/export',
        name: 'sa_export',
        component: load('Sanitaire/Export')
    },
    {
        path: '/sanitaire/toilettage',
        name: 'toilettage',
        component: load('Sanitaire/Toilettage/Index')
    },
    {
        path: '/sanitaire/toilettage/add',
        name: 'add-toilettage',
        component: load('Sanitaire/Toilettage/Add')
    },
    {
        path: '/sanitaire/toilettage/detail/:uid',
        name: 'detail_toilettage',
        component: load('Sanitaire/Toilettage/Detail')
    },
    {
        path: '/sanitaire/toilettage/update/:uid',
        name: 'update_toilettage',
        component: load('Sanitaire/Toilettage/Edit')
    },
  ]