const tour =  {
      id: "droit-access",
      steps: [
        {
          title: "Bonjour!",
          content: `Les accès aux <strong>fonctionnalités</strong> sont désormais mise à jour par rapport à votre profile. <br/> Si vous devrez avoir un accès mais qui n'est pas activé, merci d'envoyer <a href="mailto:kossi.gotri@gmail.com"><strong>un mail à l'administrateur </strong></a> pour une régularisation dans les plus bref délais. <br/><br/> Bonne journée`,
          target: 'logo',
          placement: "right",
          arrowOffset: 60,
        }
      ],
      allowedType: ["all"]
  } 

//  const tour =  {
//     id: 'examen-para-clinique',
//     steps: [
//       {
//         title: "Bienvenu!",
//         content: "La fonction d'examen para-clique est desormais accessible dans sa totalité sur <strong>CASEAC</strong>.",
//         target: 'logo',
//         placement: "bottom",
//         arrowOffset: 60,
        
//       }, 
//       {
//         title: "Liste",
//         content: "Pour accéder à la liste des examens para-clinique on clique sur <strong>Examen Para-clinique</strong> dans le menu.",
//         target: 'menu-examen-para-clinique',
//         placement: 'right',
//         multipage : true,
//       },
//       {
//         title: "Ajout de demande",
//         content: "Cliquez sur le bouton pour ajouter une demande d'analyse",
//         placement: 'bottom'
//       }
//     ],
//     allowedType: ["all"]
//   }
 
  export default tour