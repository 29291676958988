<template>
  <div>
    <li class="menu">
      <router-link
        :to="{name: 'pack-abonnements'}"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-box" />
          <span>Abonnements</span>
        </div>
      </router-link>
    </li>
    <li
      class="menu"
      v-if="is_admin"
    >
      <a
        href="#config_pack"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <div class="">
          <i class="las la-cog" />
          <span>Configuration</span>
        </div>
        <div>
          <i class="las la-angle-right sidemenu-right-icon" />
        </div>
      </a>
      <ul
        class="collapse submenu list-unstyled"
        id="config_pack"
        data-parent="#accordionExample"
      >
        <li>
          <router-link :to="{name: 'admin-packs' }">
            Liste Packs
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-packs-services' }">
            Services
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'admin-packs-modules' }">
            Modules Complémentaires
          </router-link>
        </li>
      </ul>
    </li>
  </div>
</template>
  
  <script>
  import { mapGetters, mapMutations } from 'vuex';
  import { SUBSCRIBER_PACK, SUBSCRIBER_PACK_ABONNE, SUBSCRIBER_PACK_MODULE, SUBSCRIBER_PACK_SERVICE } from '../../../graphql/pack';
  import { ADD, DELETE, UPDATE } from '../../../constants/app';
  export default {
      computed: {
          ...mapGetters({
              is_admin: 'auth/is_admin',
          })
      },
      methods: {
        ...mapMutations({
          addAbonnement: 'packs/ADD_ABONNEMENT',
          deleteAbonnement: 'packs/DELETE_ABONNEMENT',

          addPackService: 'packs/ADD_SERVICE',
          updatePackService: 'packs/UPDATE_SERVICE',
          deletePackService: 'packs/DELETE_SERVICE',

          addPackModule: 'packs/ADD_MODULE',
          updatePackModule: 'packs/UPDATE_MODULE',
          deletePackModule: 'packs/DELETE_MODULE',

          addPack: 'packs/ADD_PACK',
          updatePack: 'packs/UPDATE_PACK',
          deletePack: 'packs/DELETE_PACK'
        })
      },
      apollo: {
          $subscribe: {
            pack_abonnement: {
                  query: SUBSCRIBER_PACK_ABONNE ,
                  result({data}){
                      let pa = data.pack_abonnement
                      if(pa.type === ADD) this.addAbonnement(pa.data)
                      if(pa.type === DELETE) this.deleteAbonnement(pa.data)
                  }
            },
            pack_service:{
                query: SUBSCRIBER_PACK_SERVICE,
                    result({data}){
                        let s = data.pack_service
                        if(s.type === ADD) this.addPackService(s.data)
                        if(s.type === DELETE) this.deletePackService(s.data)
                        if(s.type === UPDATE) this.updatePackService(s.data)
                    }
              },
              pack_module:{
                query: SUBSCRIBER_PACK_MODULE,
                    result({data}){
                        let m = data.pack_module
                        if(m.type === ADD) this.addPackModule(m.data)
                        if(m.type === DELETE) this.deletePackModule(m.data)
                        if(m.type === UPDATE) this.updatePackModule(m.data)
                    }
              },
              pack:{
                query: SUBSCRIBER_PACK,
                    result({data}){
                        let p = data.pack
                        if(p.type === ADD) this.addPack(p.data)
                        if(p.type === DELETE) this.deletePack(p.data)
                        if(p.type === UPDATE) this.updatePack(p.data)
                    }
              }
            }
      }
  }
  </script>
  
  <style>
  
  </style>