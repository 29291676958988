
const load  = (component) => {
    return () => import(`../views/${component}.vue`)
  }

import adminRoutes from './admin'
import sanitaire from './sanitaire'
import market from './market'
import pack from './pack'
  
export default [
    {
        path: '/',
        name: 'home',
        component: load('Home')
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: load('Notification')
    },
    {
        path: '/profile',
        name: 'profile',
        component: load('User/Profile')
    },
    {
        path: '/notification',
        name: 'notification',
        component: load('Notification')
    },
    {
        path: '/chat',
        name: 'chat',
        component: load('Chat')
    },
    {
        path: '/rendez-vous',
        name: 'rendez_vous',
        component: load('RDV/Index')
    },
    {
        path: '/rdv/appointment',
        name: 'rdv_appointment',
        component: load('RDV/Appointment')
    },
    {
        path: '/rdv/appointment/:uid',
        name: 'rdv_appointment_detail',
        component: load('RDV/AppointmentDetail')
    },
    {
        path: '/direct-sms',
        name: 'direct_sms',
        component: load('DirectSms')
    },
    {
        path: '/login',
        name: 'login',
        component: load('Login')
    },
    {
        path: '/users',
        name: 'users',
        component: load('User/Index')
    },
    {
        path: '/add-user',
        name: 'add_user',
        component: load('User/Add')
    },
    {
        path: '/edit-user/:uid',
        name: 'edit_user',
        component: load('User/Edit')
    },
    {
        path: '/groups',
        name: 'groups',
        component: load('User/Group')
    },
    {
        path: '/detail-group/:uid',
        name: 'detail-group',
        component: load('User/DetailGroup')
    },
    {
        path: '/animaux',
        name: 'animaux',
        component: load('Identification/animal/Index')
    },
    { 
        path: '/animal/ajout',
        name: 'add_animal',
        component: load('Identification/animal/Add')
    },
    {
        path: '/animal/edit/:uid',
        name: 'animal_edit',
        component: load('Identification/animal/Edit')
    },
    {
        path: '/animal/:uid',
        name: 'animal_detail',
        component: load('Identification/animal/Detail')
    },
    {
        path: '/badge',
        name: 'badge',
        component: load('Host/Badge')
    },
    
    {
        path: '/salle/:uid',
        name: 'salle-detail',
        component: load('Host/SalleDetail')
    },
    {
        path: '/constant',
        name: 'constant',
        component: load('Identification/constante/Index')
    },
    {
        path: '/constant/graphe',
        name: 'constant-graph',
        component: load('Identification/constante/Graph')
    },
    {
        path: '/declaration-deces',
        name: 'declaration-deces',
        component: load('Identification/declarationDeces/Index')
    },
    {
        path: '/declaration-deces/add',
        name: 'declaration-deces-add',
        component: load('Identification/declarationDeces/Add')
    },
    {
        path: '/declaration-deces/detail/:uid',
        name: 'declaration-deces-detail',
        component: load('Identification/declarationDeces/Detail')
    },
    ...sanitaire,
    ...adminRoutes,
    ...market,
    ...pack,
    {
        path: '*',
        name: 'NotFound',
        // component: NotFound
    
      }
]