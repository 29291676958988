
const load  = (component) => {
    return () => import(`../views/${component}.vue`)
}


export default [
    {
        path: '/market',
        name: 'market-dashboard',
        component: load('Market/Dashboard')
    },
    {
        path: '/market/products',
        name: 'market-products',
        component: load('Market/Market')
    },
    {
        path: '/market',
        name: 'market-home',
        component: load('Market/Market')
    },
    {
        path: '/market/add-product',
        name: 'market-add-product',
        component: load('Market/AddProduct')
    },
    {
        path: '/market/product/:uid',
        name: 'market-product',
        component: load('Market/ProductDetail')
    },
    {
        path: '/market/order',
        name: 'market-order',
        component: load('Market/Order')
    },
    {
        path: '/market/order/:uid/detail',
        name: 'market-order-detail',
        component: load('Market/DetailOrder')
    },
    {
        path: '/market/billing',
        name: 'market-billing',
        component: load('Market/Facture')
    },
    
    {
        path: '/market/billing/:uid/detail',
        name: 'market-billing-detail',
        component: load('Market/DetailFacture')
    },
]