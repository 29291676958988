<template>
  <div class="notification-container">
    <Notification
      v-if="currentNotification"
      :key="currentNotification.uid"
      :notification="currentNotification"
      :type="currentNotification.type"
      :duration="10000"
      @close="handleNotificationClose"
    />
  </div>
</template>

<script>
import { SET_NOTIFICATION_AS_READ } from '../../graphql/user';
import Notification from './Notification.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "NotificationContainer",
  components: { Notification },
  data() {
    return {
      currentNotification: null,
    };
  },
  methods: {
    ...mapMutations({
      setAsReadNotification: 'notification/DEFINE_NOTIFICATION_AS_READ'
    }),
    showNextNotification() {
      if (this.notifications.length > 0) {
        this.currentNotification = this.notifications[0];
      } else {
        this.currentNotification = null;
      }
    },
    async handleNotificationClose() {
      if (this.currentNotification) {
        await this.$apollo.mutate({
          mutation: SET_NOTIFICATION_AS_READ,
          variables: {
            uid: this.currentNotification.uid
          },
          update: (cache, { data: { setNotificationAsRead } }) => {
            console.log(setNotificationAsRead);
            this.setAsReadNotification(this.currentNotification);
          }
        });
        this.notifications.shift();

        // Attendre 5 secondes avant d'afficher la prochaine notification
        setTimeout(() => {
          this.showNextNotification();
        }, 5000);
      }
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'notification/unreadNotifications'
    })
  },
  watch: {
    notifications(newNotifications) {
      if (!this.currentNotification && newNotifications.length > 0) {
        this.showNextNotification();
      }
    }
  },
  mounted() {
    this.showNextNotification();
  }
};
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px !important;
  z-index: 1000;
}
</style>
