const state = {
    resultats: [],
    reset: false,
    resultatAnalyses: [] 
}

const getters = {
    resultats: state => state.resultats,
    reset: state => state.reset,
    resultatAnalyses: state => state.resultatAnalyses
}

const mutations = {
    SET_RESULTAT: (state, resultats) => {state.resultats = resultats},
    ADD_RESULTAT: (state, resultat) => { state.resultats = state.resultats.filter(item => item.order !== resultat.order); state.resultats.push(resultat)},
    DELETE_RESULTAT: (state, resultat) => {state.resultats = state.resultats.filter(item => item.order !== resultat.order)},
    INIT_RESULTAT: (state) => {state.resultats = []},
    SET_RESET: (state, reset) => {state.reset = reset},
    
    SET_RESULTAT_ANALYSES:(state, list) => {state.resultatAnalyses = list},
    ADD_RESULTAT_ANALYSE:(state,resultat) => {state.resultatAnalyses.push(resultat)},
    UPDATE_RESULTAT_ANALYSE:(state, resultat) => {state.resultatAnalyses = state.resultatAnalyses.filter(item => item.uid !== resultat.uid); state.resultatAnalyses.push(resultat)},
    DELETE_RESULTAT_ANALYSE:(state, resultat) => {state.resultatAnalyses = state.resultatAnalyses.filter(item => item.uid !== resultat.uid)}
}

export default {
    namespaced: true,
    state, 
    getters,
    mutations
}